* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
    min-height: 100%;
}

html,
body {
    font-family: $font-body;
    font-weight: $weight-regular;
    font-size: $font-base-size;
    line-height: 1.7;
}

body {
    letter-spacing: 0.02em;
    color: $text-color;
    figcaption {
        color: #1C1E20;
        font-size: .875rem;
        font-style: italic;
        text-align: center;
    }
    &.noscroll {
        position: fixed;
        overflow: hidden;
        height: 100%;
        width: 100%;
    }
}

.svg-sprite {
    position: absolute;
    top: -100%;
    left: -100%;
    opacity: 0;
}

picture {
    img {
        width: 100%;
    }
}
a {
  color: $grey-dark;
  text-decoration: none;
  &:focus {
    outline: 1px auto #148AFF;
  }
  &:focus:not(:focus-visible) {
      outline: none;
  }
  &:focus-visible {
    outline: 1px auto #148AFF;
  }
}
h1, h2, h3 {
    font-weight: $weight-regular;
}

ul, ol {
    li {
        padding: .25em 0;
    }
}

sup {
    font-size: .65em;
    line-height: 1em;
    vertical-align: text-top;
}

img {
    border: 0;
    width: 100%;
    height: auto;
}

audio[controls] {
    margin: 1em 0;
    width: 100%;
}

svg {
    vertical-align: middle;
}

svg text {
    fill: $text-color;
}

address {
    font-style: normal;
}

dl {
    dt {
        margin: 1em 0 0;
        font-size: $zeta;
    }
    dd {
        margin: 1em 0 1em 1em;
        font-size: $epsilon;
    }
    dt + dd {
        margin-top: 0;
    }
    dt + dt {
        margin-top: 1em;
    }
}

figure {
    margin: 1em 0;
    counter-increment: figure;
    img {
        margin: 0 0 1em 0;
        max-width: 100%;
        height: auto;
    }
    figcaption {
        margin-top: $kappa;
        font-weight: $weight-light;
        color: #5F6368
    }
    figcaption:before {
        content: 'Fig ' counter(figure) ' — ';
        font-weight: $weight-regular;
    }
    figcaption.no-fig:before {
        content: none;
    }
}
fieldset {
  border: none;
}
form label[for] {
  display: block;
  margin-bottom: $single-space;
}
input, textarea, select {
  font-family: $font-body;
  font-size: 1rem;
  &:focus {
    outline: 2px auto #148AFF;
  }
  &:focus:not(:focus-visible) {
      outline: none;
  }
  &:focus-visible {
    outline: 2px auto #148AFF;
  }
}
input, textarea {
    background: $white;
    border: 1px solid $grey-light;
    padding: 0.595rem 1rem;
    font-size: 1rem;
    border-radius: 3px;
}
.button {
    padding: 1.125rem 2.25rem;
    display: inline-block;
    color: $white;
    font-size: 0.813em;
    border: 2px solid transparent;
    border-radius: 2px;
    letter-spacing:1px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: $blue-mid;
    cursor: pointer;
    transition: background-color .15s, color .15s;
    &:hover {
        background-color: transparent;
        color: $blue-mid;
        border: 2px solid $blue-mid;
    }
    &--secondary {
        background: $white;
        border: 2px solid $blue-mid;
        color: $blue-mid;
        &:hover {
            background-color: $blue-mid;
            color: $white;
        }
    }
    &--tertiary {
        background: $grey-xlight;
        color: $grey-dark;
        padding: 5px 10px;
        text-transform: none;
        letter-spacing: .5px;
        &:hover {
            background: $grey-light;
            color: $grey-xdark;
            border: 2px solid transparent;
        }
    }
    &--disabled {
        background: $grey-xlight;
        color: $grey-light;
        pointer-events: none;
    }
}

.content-wrapper {
    max-width: $site-wrapper-max-width;
    margin: 0 auto;
}

.content-wrapper:not(.full-bleed) {
    @media screen and (max-width: 92rem) {
        padding-left: $single-space * 6;
        padding-right: $single-space * 6;
    }

    @media screen and (max-width: $breakpoint-tablet) {
        padding-left: $single-space * 5;
        padding-right: $single-space * 5;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        padding-left: $single-space * 3;
        padding-right: $single-space * 3;
    }
    &--full-width {
        @media screen and (max-width: 1056px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.content-section {
    padding-top: $single-space * 5;
    padding-bottom: $single-space * 5;
    @media screen and (max-width: $breakpoint-mobile) {
        padding-top: $single-space * 3;
        padding-bottom: $single-space * 3;
    }
    &--extra-margin {
        margin-bottom: $single-space * 5;
    }
}

.promo-margin {
    margin: 0 auto;
}

/**
 * Font modifiers
 */

.italic {
    font-style: italic;
}
.bold {
    font-weight: $weight-bold;
}
.heading-font {
    font-weight: $weight-heading;
}
.heading-font, h1, h2, h3 {
    b, strong, .bold {
        font-weight: inherit;
    }
}
.body-font {
    font-family: $font-body;
    font-weight: $weight-regular;
}

.footnotes {
    margin: 4em 0;
    font-size: $eta;
    hr {
        display: none;
    }
    li {
        padding: 0;
        p {
            margin: 0;
        }
    }
}

p code,
li code {
  background: $code-bg;
  border-radius: 0.125em;
  color: $code-color;
  font-family: $font-code;
  font-size: 80%; // Match body font size a little closer
  padding: 0.45em 0.5em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  @include prefix(transition, 'color 0.25s ease-in-out, background-color 0.25s ease-in-out');

  &:hover {
      background-color: $grey-light;
      color: $grey-xdark;
  }
}

pre code {
    font-family: $font-code;
    font-size: 90%; // Match body font size a little closer
    letter-spacing: 0.0625em;
}

kbd {
    background-color: #ffffff;
    border-bottom: 2px solid $grey-light;
    display: inline-block;
    font-family: $font-symbol;
    font-size: 70%;
    outline: 1px solid $grey-mid;
    padding: 0.125em 0.5em;
    position: relative;
      bottom: 2px;
    margin-left: 0.2em;
    margin-right: 0.2em;
    min-width: 1.75em;
    text-align: center;
    text-transform: uppercase;
}

mark {
    padding: 0.125em;
}

video {
    max-width: 100%;
    height: auto;
}

.hidden {
    display: none;
}

.nolist {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: block;
        margin: 0;
        padding: 0;
    }
}

.credit {
    position: absolute;
    bottom: 1em;
    right: 1em;
    font-size: 0.5em;
    color: #ffffff;

    a {
        text-decoration: none;
        color: #ffffff;
    }
}

/**
 * Text Shadows (used for better contrast)
*/

 $shadows: (
     newport-folk-festival: #242736,
     vista-higher-learning: #232324,
 );

 // Loops through `$shadows` to generate text shadow classes
 @each $shadow, $color in $shadows {
     .ts-#{$shadow} {
         text-shadow: 0 0 10px rgba($color, 0.75);
     }
 }

// Embeds
.dca-header {
    font-size: 3.4rem;
    margin: 0 auto 1.3rem;
    @media(min-width: 50rem) {
        width: 80%;
    }
    @media(max-width: 50rem) {
        font-size: 2.5rem;
        text-align: center;
    }
    svg {
        height:134px;
        margin-right: 1rem;
        width: 134px;
        @media(max-width: 50rem) {
            display: none;
        }
    }
    span {
        text-shadow: -1px 1px 0 #ffffff, -3px 3px 0 #C6C6C6;
        @media(max-width: 50rem) {
            text-shadow: -1px 1px 0 #ffffff, -2px 2px 0 #C6C6C6;
        }
    }
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $grey-light;
    margin: $single-space * 4 0;
    padding: 0;
}
.hr--dark {
    border-color: $grey-dark;
}
.hr--full-width {
    margin: 0;
}
// hub spot form styling
.hs-error-msgs {
    list-style: none;
}
.hs-error-msg {
    color: $red;
    display: block;
    background-repeat: no-repeat;
    background-position-x: 5px;
    background-position-y: 6px;
}
.invalid,.error {
    border: 2px solid $red;
    position: relative;
}
.post-submit {
    display: none;
}

.page-blog {
    .blog-list {
        .content-section {
            padding-bottom: 0;
        }
    }
    .blog-load-more {
        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
            text-align: center;
        }
        @media screen and (min-width: 56.063rem) {
            margin-left: 25%;
        }
    }
}
.content-max-width {
    max-width: 650px;
}

.page-home {
    .card-container {
        @media screen and (max-width: 66rem) {
            // width: 32%;
        }
    }
}

.fluid-width-video-wrapper{
    width:100%;
    position:relative;
    padding:0;
}
.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
};
.highlight {
    padding: $single-space;
    margin-bottom: $single-space * 2;
    overflow: scroll;
}

#BambooHR > div a img {
    width: auto;
}