.content-image-50-50-banner-full {
    display: flex;
    align-items: center;
    @media screen and (max-width: 64rem) {
        flex-direction: column;
    }
    .content-container { 
        width: 50%;
        padding: 60px 10% 32px 10%;
        @media screen and (max-width: 64rem) {
            max-width: 650px;
            padding: 50px 30px 0 30px;
            width: auto;
        }
    }
    .image-container { 
        width: 50%;
        @media screen and (max-width: 64rem) {
            max-width: 650px;
            width: auto;
        }
        img { 
            display:block;
            object-fit: cover;
        }
    }
}
