.social-media-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: $single-space * 2;
    border-bottom: 1px solid $grey-light;
    margin-bottom: $single-space * 4;
    @media screen and (min-width: 50rem) and (max-width: 77rem) {
        border-top: 1px solid $grey-light;
        padding-top: $single-space * 2;
    }
    a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    a:hover svg {
        path {
            fill: $grey-xlight;
        }
    }
    svg {
        width: 100%;
        path {
            fill: $grey-light;
        }
    }
}
