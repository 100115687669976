.download-app {
    margin-top: $single-space * 6;
    p {
        margin-bottom: $single-space * 2;
    }
}
.download-app-icon-wrapper {
    display: flex;
    justify-content: space-around;
    margin: $single-space * 8 0;
    align-items: center;
    @media screen and (max-width: 32rem) {
        flex-direction: column;
    }
    a {
        max-width: 200px;
        display: block;
    }
}