.percent-stats {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    padding: $single-space * 4 0;
    margin: $single-space * 4 0;
    @media screen and (max-width: 36rem) {
        flex-direction: column;
    }
}
.percent-stat-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.3%;
    &:last-child {
        margin-right: 0;
    }
    @media screen and (max-width: 36rem) {
        width: 100%;
        margin-top: $single-space * 4;
        margin-right: 0;
        &:first-child {
            margin-top: 0;
        }
    }
    .percent-stat-text {
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 0;
        padding: 0 $single-space;
        max-width: 200px;
        &--left-align {
            text-align: left;
        }
    }
}
.percent-stat {
    font-size: 70px;
    color: $blue;
    position: relative;
    line-height: 1;
    margin-bottom: $single-space * 4;
    &.up {
        &:before {
            content: '';
            width: 0;
            height: 0;
            left:  -$single-space * 2;
            bottom: 50%;
            transform: translateY(16px);
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid $blue;
        }
    }
    &.down {
        &:before {
            content: '';
            width: 0;
            height: 0;
            left: -$single-space * 2;
            bottom: 50%;
            transform: translateY(16px);
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 10px solid $blue;
        }
    }
}
.percent-stat-with-copy {
    display: flex;
    align-items: flex-end;
    .percent-stat {
        font-size: 5.625rem;
        margin-right: $single-space * 4;
    }
}

.percent-stat-symbol {
    font-size: 1.875rem;
    margin-left: -$single-space
}


/* Horizontal Classes Below */

.percent-stats-horizontal {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    padding: $single-space * 4 0;
    margin: $single-space * 4 0;
    @media screen and (max-width: 36rem) {
        flex-direction: column;
    }
}
.percent-stat-card-horizontal {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    &:last-child {
        margin-right: 0;
    }
    @media screen and (max-width: 36rem) {
        width: 100%;
        margin-top: $single-space * 4;
        margin-right: 0;
        &:first-child {
            margin-top: 0;
        }
    }
    .percent-stat-text-horizontal {
        text-align: left;
        font-size: 0.875rem;
        line-height: 1.5;
        margin-bottom: 0;
        padding: 0 $single-space * 3;
        max-width: 460px;
        &--left-align {
            text-align: left;
        }
        @media screen and (max-width: 72rem) {
            max-width: 360px;
        }
        @media screen and (max-width: 36rem) {
            text-align:center;
            padding: $single-space * 3 $single-space * 3 0 $single-space * 3
        }
    }
}
.percent-stat-horizontal {
    font-size: 70px;
    color: $blue;
    position: relative;
    line-height: 1;
    &.up {
        &:before {
            content: '';
            width: 0;
            height: 0;
            left:  -$single-space * 2;
            bottom: 50%;
            transform: translateY(16px);
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 10px solid $blue;
        }
    }
    &.down {
        &:before {
            content: '';
            width: 0;
            height: 0;
            left: -$single-space * 2;
            bottom: 50%;
            transform: translateY(16px);
            position: absolute;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 10px solid $blue;
        }
    }
}
.percent-stat-with-copy-horizontal {
    display: flex;
    align-items: flex-end;
    .percent-stat {
        font-size: 5.625rem;
        margin-right: $single-space * 4;
    }
}

.percent-stat-symbol-horizontal {
    font-size: 1.875rem;
    margin-left: -$single-space
}