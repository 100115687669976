.call-out-banner-no-padding {
    .content-container-wrapper { 
        display: flex;
        height: 323px;
        @media screen and (max-width: 64rem) {
            flex-direction: column;
            height: auto;
        }
        .content-container { 
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 57.5%;
            padding: 25px 120px 25px 80px;
            @media screen and (max-width: 64rem) {
                width: auto;
                padding: 25px;
            }
            > h2.header-small { 
                margin-bottom: 15px;
            }
            > p.text-body { 
                margin-bottom: 15px;
            }
        }
        .image-container { 
            display: flex;
            justify-content: center;
            width: 42.5%;
            @media screen and (max-width: 64rem) {
                width: auto;
            }
            img { 
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
