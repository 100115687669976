.design-features {
    .design-feature-item {
        flex: 1;
        text-align: center;
        color: #fff;
        background-color: #333;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        .design-feature-item-wrapper {
            margin: 0 auto;
            padding: 3rem 3rem 15rem 3rem;
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
            max-width: 28rem;
        }
        .design-feature-item-info {
            img {
                height: 40px;
            }
        }
        .screenshot {
            position: absolute;
            top: auto;
            left: calc(50% - 250px / 2);
            height: auto;
            width: 250px;
        }
    }
    @media screen and (min-width: 80em) {
        display: flex;
    }
}
