/*
Color Variables
 */

$yellow: #F5BB41;
$yellow-light: #FFDD33;
$orange: #FFA200;
$orange-mid: #FF7900;
$red-orange: #FF5D00;
$red: #E75332;
$blue: #148AFF;
$blue-xxlight:#ECF3FF;
$blue-xlight: #AAC9FF;
$blue-light: #77A7FF;
$blue-mid: #1574D4;
$blue-dark: #2851A3;
$blue-svcs: #4191CF;
$blue-navy: #1D3C78;
$purple:#9575CD;
$purple-dark:#4527A0;
$green: #24A148;
$green-dark:#186E30;
$green-svcs: #679431;
$white: #ffffff;
$grey-xlight: #F5F5F5;
$grey-light: #A4A5A6;
$grey-light-alpha: #A4A5A650;
$grey-mid: #5F6368;
$grey-dark: #323436;
$grey-xdark: #1C1E20;
$black: #000000;
// Updated Colors based on Milton's new case study designs
$grey-100: #ECECEC;

$colorMap: (
  'yellow': $yellow,
  'yellow-light': $yellow-light,
  'orange': $orange,
  'orange-mid': $orange-mid,
  'red-orange': $red-orange,
  'blue': $blue,
  'blue-xlight': $blue-xlight,
  'blue-xxlight': $blue-xxlight,
  'blue-light': $blue-light,
  'blue-mid': $blue-mid,
  'blue-dark': $blue-dark,
  'green': $green,
  'white': $white,
  'grey-xlight': $grey-xlight,
  'grey-light': $grey-light,
  'grey-mid': $grey-mid,
  'grey-xdark': $grey-xdark,
  'grey-dark': $grey-dark,
  'black': $black,
  'grey-100': $grey-100
);

/**
 * Backgrounds
 */

@each $colorName, $colorVal in $colorMap {
  // Background
  .bg-#{$colorName} {
    background: $colorVal;
  }

  // Color
  .#{$colorName} {
    color: $colorVal;
  }
}

/**
 * Code
 */

// Match highlight JS colors - Theme: Zenburn
$code-bg: $grey-xlight;
$code-color: $grey-dark;
