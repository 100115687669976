$cs-color-dark-text: #e3e3e3;
$cs-color-dark-bg: #222222;

.review-carousel {
  position: relative;
  padding: 4rem 2rem;
  background-size: cover;
  background-position: center center;
  overflow: auto;
  blockquote img {
    width: 15px;
    height: 15px;
  }
  blockquote {
    text-align: center;
    p {
      text-align: center;
      font-size: $beta;
      font-weight: $weight-heading;
      line-height: 1.3;
    }
  }
  &.dark {
    font-weight: $weight-heading;
    color: $cs-color-dark-text;
    background-color: $cs-color-dark-bg;
  }
  .inner-wrapper {
    margin: 0 auto;
    max-width: 600px;
    position: relative;
  }
  .item-group {
    cursor: pointer;
    @include prefix("user-select", none);

    .item-wrapper {
      padding: 4rem 0;
      @include prefix-value(display, flex);
      @include prefix("align-items", center);
      @include prefix("justify-content", center);
    }

    .item {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      @include prefix(transform, translateY(-50%));
      opacity: 0;
      @include prefix(transition, "opacity .15s");
      &.active {
        opacity: 1;
        @include prefix(transition, "opacity 1s");
      }
    }
  }

  .item-pagination {
    text-align: center;

    button {
      font-family: inherit;
      font-size: inherit;
      line-height: 1;
      color: $grey-dark;
      text-decoration: none;
      border: 0;
      background: transparent;
      cursor: pointer;
      @include prefix(appearance, none);
      @include prefix(transition, "color .5s");

      &.active {
        color: $white;
      }
    }
  }

  .attribution {
    position: absolute;
    right: 1em;
    bottom: 1em;
    font-size: 0.6rem;
    color: $white;
    text-decoration: none;
  }
}

.cs-tools {
  text-align: center;
  background-color: #f4f4f4;
  .logos {
    @include prefix(filter, grayscale(100%));
    img {
      margin: 1rem;
      max-width: calc(100vw - 6rem);
      height: auto;
      vertical-align: middle;
    }
  }
}
