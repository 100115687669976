.tertiary-card {
    align-items: center;
    border-right: 1px solid $grey-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $single-space * 4 $single-space * 10;
    flex: 1 0 0%;
    @media screen and (max-width: 62rem) {
        border-right: none;
        border-bottom: 1px solid $grey-light;
        margin-bottom: 4rem;
        padding-bottom: 5rem;
    }
    @media screen and (max-width: 37rem) {
        padding-left: 0;
        padding-right: 0;
    }
    &:last-child {
        border: none;
        margin-bottom: 0;
    }
    h2, p {
        text-align: center;
        margin-bottom: $single-space * 6;
        width: 100%;
    }
}