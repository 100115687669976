.contact {
  .hs-form {
    width: 100%;
    fieldset {
      width: 100%;
    }

    h1 {
      margin: 0 0 3rem 0;
      line-height: 1.3;
    }

    p {
      margin: 0 0 1rem 0;
    }

    a {
      color: $blue-mid;
    }

    .hs-form-field:not(.hs-fieldtype-booleancheckbox) {
      width: 100%;
      margin-bottom: $single-space * 4;
      vertical-align: top;
      .hs-input {
        width: 100%;
      }
    }

    .hs-fieldtype-booleancheckbox {
      margin-bottom: $single-space * 2;

      label {
        display: flex;
        align-items: center;
      }
      ul {
        list-style: none;
      }
      li {
        padding: 0;
      }
      .hs-input {
        width: 14px;
        margin-right: $single-space * 2;
      }
    }

    .hs-fieldtype-textarea {
      textarea {
        min-height: 150px;
      }
    }

    .form-columns-2 {
      .hs-form-field {
        width: 50%;
        display: inline-block;
        @media screen and (max-width: 37.5rem) {
          width: 100%;
        }
        &:first-child {
          padding-right: $single-space;
          @media screen and (max-width: 37.5rem) {
            padding-right: 0;
          }
        }
        &:last-child {
          padding-left: $single-space;
          @media screen and (max-width: 37.5rem) {
            padding-left: 0;
          }
        }
      }
    }

    .hs-button {
      @extend .button;
      margin-top: $single-space * 6;
      -webkit-appearance: none;
      appearance: none;
      @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
        text-align: center;
      }
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      &.secondary {
        @extend .button--secondary;
      }
    }
  }
}

.page-team {
  .contact {
    margin-bottom: $single-space * 5;
  }
}
