.page-navigation-sidebar {
    margin-right: $single-space * 6;
    @media screen and (max-width: 56rem) {
        margin-bottom: $single-space * 6;
        margin-right:0px;
    }
    .page-navigation-sidebar-wrapper:last-child {
        border-bottom: 6px solid $blue;
        .page-navigation-sidebar-item:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
    .page-navigation-sidebar-header {
        margin-bottom: $single-space * 3;
    }
    .page-navigation-sidebar-item {
        border-bottom: 1px solid $grey-light;
        padding-bottom: $single-space * 2;
        margin-bottom: $single-space * 2;
        &:last-child {
            margin-bottom: $single-space * 3;
        }
        a {
            display: block;
            margin-bottom: 0;
            line-height: 1.5;
        }
    }
}