.card-feature-banner-container {
    background-repeat: no-repeat;
    background-size: cover;
}
.card-feature-banner {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 6rem 0;
    .card-container {
        margin-bottom: 0;
    }
}
.card-feature-banner-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .card {
        border-left: none;
        border-right: none;
    }
}