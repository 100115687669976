.content-banner {
    @media screen and (min-width: 56rem) {
        align-items: flex-start;
        display: flex;
    }
    @media screen and (max-width: 56rem) {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
}
.content-banner-header {
    width: 25%;
    a, h2 {
        margin-bottom: $single-space * 6;
    }
    @media screen and (max-width: 63rem) {
        width: 215px;
    }
    @media screen and (max-width: 56rem) {
        width: 100%
    }
}
.content-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.content-banner-content {
    width: 60%;
    @media screen and (max-width: 56rem) {
        width: 100% !important //overide inline template style
    }
    .button {
    @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
        text-align: center;
        }
    }
}
.content-banner-graphic {
    margin: $single-space * 4 0;
}
.content-banner-cta-container {
    margin-top: $single-space * 3;
    display: flex;
}
.content-banner-container--sans-padding-top {
    padding-top: 0;
}
.content-banner-centered-text {
    padding: 9% 0 10% 0;
    text-align: center;
    @media screen and (max-width: $breakpoint-tablet) {
        padding: 21% 0 21% 0;
        .text-body--xlarge--condensed {
            font-size: 2.435rem; //39px
           line-height: 2.8125rem; //45px
         }
    }
}
