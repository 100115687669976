.white-paper-signup-wrapper {
    display: flex;
    padding-left: $single-space * 5;
    padding-right: $single-space * 5;
    padding-top: $single-space * 12;
    padding-bottom: $single-space * 12;
    @media screen and (max-width: 63rem) {
         flex-direction: column;
         height: auto;
         padding: 0px;
    }
}
.white-paper-signup-banner {
    padding: $single-space * 8 0 $single-space * 8 $single-space * 8;
    width: 50%;
    @media screen and (max-width: 63rem) {
         padding: $single-space * 4 $single-space * 4 $single-space * 4 $single-space * 4;
         text-align: center;
         width: 100%;
    }
    @media screen and (max-width: $breakpoint-phone) {
        padding: $single-space * 10 $single-space * 3 $single-space * 8 $single-space * 3;
    }
    > div {
        flex: 1 0 0%;
        &:first-child {
            padding-right: $single-space * 13;
            @media screen and (max-width: 80rem) {
                padding-right: $single-space * 4;
            }
            @media screen and (max-width: 63rem) {
                padding-right: 0;
                margin-bottom: $single-space * 8;
            }
            @media screen and (max-width: 32rem) {
                margin-bottom: $single-space * 5;
            }
            p {
                margin-bottom: 0;
            }
        }
        &:last-child {
            padding-left: 0;
            @media screen and (max-width: 80rem) {
                padding-left: 0;
            }
            @media screen and (max-width: 63rem) {
                padding-left: 0;
                font-size: 1.25rem;
                line-height: 24px;
            }
            p {
                margin-top: $single-space * 2;
                margin-bottom: 0;
                line-height: 24px;
                @media screen and (max-width: 63rem) {
                    margin-top: $single-space * 6;
                }
                @media screen and (max-width: 32rem) {
                    margin-top: $single-space * 3;
                }
            }
        }
    }
    .white-paper-teaser {
        @media screen and (max-width: 63rem) {
            font-size: 1.25rem;
            line-height: 2.0rem;
         }
    }
    .white-paper-terms {
        @media screen and (max-width: 63rem) {
          font-size: 0.875rem;
          line-height: 1.5rem;
        }
    }
    // hub spot styling
    .hs_email {
        width: 75%;
        @media screen and (max-width: 32rem) {
            width: 100%;
            margin-bottom: $single-space * 2;
        }
        input {
            width: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        label {
            margin-bottom: 0;
        }
    }
    .hs-submit {
        width: 25%;
        margin-top: 27px; // Adding this to align the button with the input, now that there is a proper label 
        @media screen and (max-width: 32rem) {
            width: 100%;
        }
        input {
            width: 100%;
            height: 40px;
            appearance: none;
            -webkit-appearance: none;
            border-radius: 0;
            padding: 0.595rem 1rem;
        }
    }
    .hs-form {
        display: flex;
        @media screen and (max-width: 32rem) {
            flex-direction: column;
        }
    }
    .hs-button {
      @extend .button;
      margin-top: $single-space * 1;
      -webkit-appearance: none;
      appearance: none;
      @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
        text-align: center;
      margin-top: $single-space * 5;
      }
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      &.secondary {
        @extend .button--secondary;
      }
    }
    .muted-text {
        color: $grey-mid;
    }
}
.white-paper-signup-image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border: 2px solid $grey-xlight;
      width: 50%;
      @media screen and (max-width: 63rem) {
          background-size: cover;
          width: 100%;
          height: 392px;
      }
}
.white-paper-content {
    @media screen and (max-width: 63rem) {
         flex-direction: column;
         height: auto;
    }
}
.bg-grey-xlight-responsive-white-mobile {
    background: #F5F5F5;
    @media screen and (max-width: 63rem) {
        background: #FFFFFF;
    }
}