.logos-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 42rem){
        flex-direction: column;
        img { 
            width: 40% !important;
        }
        img:not(:last-child) { 
            margin-bottom: $single-space * 3; 
        }
    }
}
