.configurable-content-image-banner-content {
    display: flex;
    @media screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
        height: auto;
         .configurable-text {
            padding: ($single-space * 3) 0px 0px 0px !important;
            width: 100% !important;
         }
         .configurable-image {
            margin: auto;
            width: 100% !important;
            height: 70%;
            object-fit: cover;
         }
    }
}
.configurable-image {
      display: block;
}
.mobile-flex-column-reverse {
    .configurable-mobile-image {
      display: none;
      margin: auto;
      padding: ($single-space * 5) ($single-space * 3) ($single-space * 6) ($single-space * 3);
      width: 100%;
      height: 100%;
    }
     @media screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
        height: auto;
        .configurable-mobile-image {
          display: block;
        }
        .configurable-image {
          display: none;
        }
     }
}