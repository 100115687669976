.call-out-banner {
    padding-top: $single-space * 4;
}
.call-out-banner-container {
    display: flex;
}
.call-out-banner-double-diamond {
    position: relative;
    padding: ($single-space * 6) calc(555px + 8%) ($single-space * 10) ($single-space * 8);
    img {
        position: absolute;
        bottom: 60px;
        right: $single-space * 6;
        width: 555px;
    }

    @media screen and (max-width: 77rem) {
        margin-right: 10%;
        padding: $single-space * 5;
        padding-right: calc(35% + 40px);
        img {
            width: 45%;
            right: -10%;
            bottom: 50%;
            transform: translateY(50%);
        }
    }
    @media screen and (max-width: 43rem) {
        margin-right: 0;
        padding: ($single-space * 5) ($single-space * 3);
        img {
            position: static;
            width: 100%;
            transform: none;
            margin-bottom: $single-space * 4;
        }
        @media screen and (max-width: 32rem) {
            padding: ($single-space * 5) 0;
            a {
                display: block;
                text-align: center;
            }
        }
    }
}

.call-out-banner-content {
    position: relative;
    padding: ($single-space * 6) calc(555px + 8%) ($single-space * 6) ($single-space * 8);
    img {
        position: absolute;
        bottom: 0;
        right: $single-space * 6;
        width: 555px;
    }

    @media screen and (max-width: 77rem) {
        margin-right: 10%;
        padding: $single-space * 5;
        padding-right: calc(35% + 40px);
        img {
            width: 45%;
            right: -10%;
            bottom: 50%;
            transform: translateY(50%);
        }
    }

    @media screen and (max-width: 43rem) {
        margin-right: 0;
        padding: ($single-space * 5) ($single-space * 3);
        img {
            position: static;
            width: 100%;
            transform: none;
            margin-bottom: $single-space * 4;
        }
        @media screen and (max-width: 32rem) {
            padding: ($single-space * 5) 0;
            a {
                display: block;
                text-align: center;
            }
        }
    }
}
