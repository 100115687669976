.content-image-banner-large-image {
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width: $breakpoint-phone) {
      height: 900px;
    }
}
.content-image-banner-small-image {
     width: 25%;
     @media screen and (max-width: $breakpoint-phone) {
         width: 50%;
     }
}
.content-image-banner-large-text {
    margin:auto;
    text-align: left;
    max-width: 55%;
    padding: 15% 0 10% 0;
    @media screen and (max-width: $breakpoint-phone) {
        padding: 160px 0 160px 0;
        max-width: 80%;
    }
}
.content-image-banner-large-text-centered {
    margin: auto;
    text-align: center;
    max-width: 70%;
    padding: 5% 0 5% 0;
    .text-body {
        @media screen and (max-width: $breakpoint-phone) {
            margin-bottom: $single-space * 2;
        }
    }
    .text-body--medium-condensed {
            @media screen and (max-width: $breakpoint-phone) {
                font-size: 1.25rem; //20px
                margin-bottom: $single-space;
            }
    }
    @media screen and (max-width: $breakpoint-phone) {
        height: 57%;
        margin: auto auto 0 auto;
        max-width: 90%;
    }
}