@import "../node_modules/swiper/swiper.scss";
@import "../node_modules/swiper/components/pagination/pagination.scss";
.card-container {
    padding-bottom: 2rem;
    &.swiper-slide {
        height: auto;
    }
}
.insights-swiper-container, .work-swiper-container {
    width: 100%;
    .cards-banner {
        @media screen and (max-width: 768px) {
            // flex-wrap: initial;
            // justify-content: left;
        }
    }
    .swiper-pagination, .swiper-button-next, .swiper-button-prev {
        @media screen and (min-width: 767px) {
            display: none;
        }
    }
}
.swiper-pagination, div.swiper-button-next, div.swiper-button-prev {
    display: none;
}

.swiper-pagination-fraction, .swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 18px;
}

.swiper-slide-prev:after, .swiper-slide-next:after{
    width:100%;
    height:100%;
    background: $white;
    opacity: .8;
    content:"";
    display:block;
    position: absolute;
    @media screen and (max-width:619px) {
        display:none;
    }
    @media screen and (min-width:1056px) {
        display:none;
    }
}

.swiper-button-next, .swiper-button-prev {
    color: $blue;
    width: 56px;
    height: 56px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    z-index: 20;
}

.swiper-button-prev {
    left: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iY3VzdG9tLWJ1dHRvbi1wcmV2IiB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPjxjaXJjbGUgY3g9IjI4IiBjeT0iMjciIHI9IjI0IiB0cmFuc2Zvcm09InJvdGF0ZSgtMTgwIDI4IDI3KSIgZmlsbD0iIzE0OEFGRiIvPjwvZz48cGF0aCBkPSJNMjIuODggMjdsOC04TDMyIDIwLjEyIDI1LjEyIDI3IDMyIDMzLjg4IDMwLjg4IDM1bC04LTh6IiBmaWxsPSIjZmZmIi8+PGRlZnM+PGZpbHRlciBpZD0iZmlsdGVyMF9kIiB4PSIwIiB5PSIwIiB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj48ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPjxmZU9mZnNldCBkeT0iMSIvPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjIiLz48ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMTUgMCIvPjxmZUJsZW5kIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPjxmZUJsZW5kIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPjwvZmlsdGVyPjwvZGVmcz48L3N2Zz4=');
}

.swiper-button-next {
    right: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iY3VzdG9tLWJ1dHRvbi1uZXh0IiB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPjxjaXJjbGUgY3g9IjI4IiBjeT0iMjciIHI9IjI0IiBmaWxsPSIjMTQ4QUZGIi8+PC9nPjxwYXRoIGQ9Ik0zMy4xMiAyN2wtOCA4TDI0IDMzLjg4IDMwLjg4IDI3IDI0IDIwLjEyIDI1LjEyIDE5bDggOHoiIGZpbGw9IiNmZmYiLz48ZGVmcz48ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI1NiIgaGVpZ2h0PSI1NiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+PGZlQ29sb3JNYXRyaXggaW49IlNvdXJjZUFscGhhIiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIi8+PGZlT2Zmc2V0IGR5PSIxIi8+PGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMiIvPjxmZUNvbG9yTWF0cml4IHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4xNSAwIi8+PGZlQmxlbmQgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+PGZlQmxlbmQgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+PC9maWx0ZXI+PC9kZWZzPjwvc3ZnPg==');
}
