.instagram-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: $single-space * 6;
}
.instagram-item {
    width: 23%;
    margin-bottom: $single-space * 2;
    @media screen and (max-width: 48rem){
       width: 30%; 
    }
    @media screen and (max-width: 33rem){
        width: 44%; 
    }
    @media screen and (max-width: 28rem){
        width: 100%; 
    }
}