$breakpoint-tablet: 960px;
$breakpoint-mobile: 768px;
$breakpoint-phone: 480px;

/**
 * LEGACY DO NOT USE THESE 
 * Breakpoints going to delete these
 * once we figure out what to do with the case study styles
 */
$bp-colossal: 85.625em; // 1370px @ $font-base-size
$bp-huge: 70em;     // 1120px @ $font-base-size
$bp-largest: 65em;  // 1040px @ $font-base-size
$bp-larger: 60em;   // 960px @ $font-base-size
$bp-large: 50em;    // 800px @ $font-base-size
$bp-medium: 45em;   // 720px @ $font-base-size
$bp-small: 40em;    // 640px @ $font-base-size
$bp-smaller: 35em;  // 560px @ $font-base-size
$bp-smallest: 30em; // 480px @ $font-base-size
$bp-tiny: 20em;     // 320px @ $font-base-size


/**
 * Base
 */
$text-color: $grey-xdark;
$site-wrapper-max-width: 82.5rem; // 1320px

// spacing
$single-space: 10px;

/**
 * Font Sizes
 */

// Sizes (px)
$font-base-size: 16px;

// Sizes (rem)
// * LEGACY DO NOT USE THESE 
$ultra: 6rem;       //96px
$peta: 5rem;        //80px
$tera-2: 4.5rem;    //72px
$tera: 4rem;        //64px
$giga: 3.125rem;    //50px
$mega: 2.5rem;      //40px
$alpha: 1.875rem;   //30px
$beta-2: 1.75rem;   //28px
$beta: 1.5rem;      //24px
$gamma: 1.250rem;   //20px
$delta: 1.125rem;   //18px
$epsilon: 1rem;     //16px
$zeta: 0.875rem;    //14px
$theta: 0.8125rem;   //13px
$eta: 0.75rem;      //12px
$iota: 0.5rem;      //8px
$kappa: 0.25rem;     //4px


/**
 * Font Families
 */
$font-body: "Lato", "Helvetica", "Arial", sans-serif;
$font-body: "Lato", "Helvetica", "Arial", sans-serif;
$font-code: "Consolas", "Lucida Console", "monaco", monospace; // Nicer monospace font with Windows fallback
$font-symbol: "Helvetica", "Arial", sans-serif;


/**
 * Font Weights
 */
$weight-thin: 100;
$weight-heading: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-bold: 700;
