.client-tabs {
    @media screen and (max-width: $breakpoint-tablet) {
        display: none;
    }
}
.client-tab-controls {
     display: flex;
     padding-bottom: $single-space * 4;
     border-bottom: 1px solid $grey-light;
     justify-content: flex-start;
     flex-wrap: wrap;
     margin-left: 5%;
     button, a {
         padding: $single-space 15px;
         border: none;
         background: none;
         &.active {
             background: $grey-xdark;
             color: $white;
         }
     }
 }
.client-tabs-control-button, .client-tabs-control-button-mobile {
     cursor: pointer;
}
.client-tabs-mobile {
    display: none;
    padding-bottom: $single-space * 4;
    @media screen and (max-width: $breakpoint-tablet) {
        display: flex;
        flex-direction: column;
    }
}
.client-tabs-control-button-mobile {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: $single-space * 1 $single-space * 3 $single-space * 1 $single-space * 3;
    margin-top: $single-space * 1;
    background: $black;
}
.client-tabs-control-button-mobile-active {
  .client-tabs-control-button-mobile-arrow {
      background-image: url("../images/industries/financial-services/downarrow.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
  }
}
.client-tabs-control-button-mobile-inactive {
  .client-tabs-control-button-mobile-arrow {
      background-image: url("../images/industries/financial-services/rightarrow.png");
      background-size: contain;
      background-repeat: no-repeat;
      width: 23px;
  }
}