// Alignment
.align-left { float: left; }
.align-right { float: right; }
.align-top { vertical-align: top; }
.align-bottom { vertical-align: bottom; }
.align-middle { vertical-align: middle; }

// Text
.text-break-word,
.text-break-link {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}

.text-break-word { hyphens: auto; }
.text-break-link { hyphens: none; }

.text-symbol { font-family: $font-symbol; }

.text-align-left { text-align: left; }
.text-align-center { text-align: center; }
.text-align-right { text-align: right; }

.text-remove-bullets { list-style: none; }

// Display
.display-inline { display: inline; }
.display-block { display: block; }
.display-inline-block { display: inline-block; }
.display-table { display: table; }
.display-table-cell { display: table-cell; }
.display-table-row { display: table-row; }

.hide-on-mobile {
    @extend %visuallyhidden;
}

.visuallyhidden {
    @extend %visuallyhidden;
}

// Images
.size-full {
    height: auto;
    max-width: 100%;
}

// Image Width
.img-full-width,
.img-half-width,
.img-three-quarters-width
.img-quarter-width {
  display: block;
  height: auto;
}

.img-full-width {
  padding-top: $single-space;
  padding-bottom: $single-space;
  width: 100%;
}

.img-half-width {
  width: 50%;
}

.img-three-quarters-width {
    width: 75%;
}

.img-quarter-width {
  width: 100%;
}

// Image Alignment
.img-centered {
  margin: 0 auto;
}

.img-wrap-left,
.img-wrap-right {
  padding-top: $single-space;
  padding-bottom: $single-space;

  @media screen and (min-width: $bp-small) {
    display: inline;
  }
}

.img-wrap-left {
  @media screen and (min-width: $bp-small) {
    float: left;
    padding: 0 $single-space $single-space 0;
  }
}

.img-wrap-right {
  @media screen and (min-width: $bp-small) {
    float: right;
    padding: 0 0 $single-space $single-space;
  }
}

.mb-4 {
  margin-bottom: $single-space * 4;
}
