.centered-content-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    margin: 0 auto;
    @media screen and (max-width: 88rem) {
        width: 55%; 
    }
    @media screen and (max-width: 63rem) {
        width: 70%; 
    }
    @media screen and (max-width: 53rem) {
        width: 100%; 
    }
    h2, p {
        text-align: center;
    }
}