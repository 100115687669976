.social-links {
    svg {
        margin-right: 5px;
    }
    .email {
        svg {
            stroke: $grey-mid;
        }
    }
    .dribble { 
        svg {
            width: 32px;
            height: 32px;
        }
    }
}
