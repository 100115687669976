.card-container {
    position:relative;
    display: flex;
    margin-bottom: $single-space * 4;
    width: 32%;
    max-width: 310px;
    overflow: hidden;
    @media screen and (max-width: 66rem) {
        width: 46%;
    }
    @media screen and (max-width: 42rem) {
        width: 100%;
    }
    > a {
        display: flex;
        flex-direction: column;
        flex: 1 0 0%;
        flex-grow: 1;
        &:hover {
            img {
                min-width: 120%;
                min-height: 120%;
            }
            .text-body--link-with-arrow {
                svg {
                    margin-left: 20px;
                }
            }
        }
    }
    .img-container {
        align-items: center;
        display: flex;
        height: 181px;
        justify-content: center;
        overflow: hidden;
        border: 1px solid $grey-light-alpha;
    }
    img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        transition: all .15s;
    }
}
.card {
    background: $white;
    border-bottom: 6px solid $blue-mid;
    border-left: 1px solid $grey-light-alpha;
    border-right: 1px solid $grey-light-alpha;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: $single-space * 3;
    .card-eyebrow {
        margin-bottom: $single-space;
        line-height: 1;
    }
    h2 {
        margin-bottom: $single-space * 3;
    }
    .card-cta {
        margin-bottom: 0;
        align-self: flex-start;
    }
    > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}




