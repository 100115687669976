.post-category-buttons {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    @media screen and (min-width: $breakpoint-mobile) {
        width: 75%;
    }
    li {
        margin-right: $single-space * 2;
        margin-bottom: $single-space * 2;
        padding: 0;
    }
}