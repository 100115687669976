.blog-content {
    > p:first-child {
        @extend .header-extra-small;
        margin-bottom: $single-space * 4;
    }
    p {
        @extend .text-body;
    }
    p a:not(.button) {
        color: $blue-mid;
        &:hover {
            text-decoration: underline;
        }
    }
    table td p {
      margin-bottom: $single-space * 2;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
        margin-bottom: $single-space * 4;
        @extend .bullet-list;
    }
    ol {
        margin-bottom: $single-space * 4;
        @extend .number-bullet-list;
    }
    li {
        @extend .text-body;
        margin-bottom: $single-space; 
    }
    h2:not(.header-small), h4:not(.header-small) {
        @extend .header-extra-small;
        @extend .header-extra-small--bold;
        margin-bottom: $single-space;
        a {
            color: $grey-xdark;
            &:hover[href] {
                text-decoration: underline;
            }
        }
    }
    figure {
        figcaption {
            margin-top: 0;
        }
    }
    figcaption {
        margin-top: -$single-space * 4;
        margin-bottom: $single-space * 2;
    }
    table + figcaption {
        margin-top: 0;
    }
    blockquote {
        padding-left: 110px;
        position: relative;
        color: $blue-mid;
        min-height: 83px; // 67px svg height + 8px padding top and bottom
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: $single-space * 4;

        &:before {
            content: '';
            width: 67px;
            height: 67px;
            position: absolute;
            left: 0;
            top: 8px;
            background: url(/images/quote.svg) no-repeat;
        }
        @media screen and (max-width: 56rem) {
            padding-left: 0;
            padding-top: 110px;
          }
        p {
            font-size: 1.375rem;
            color: $blue-mid;
            line-height: 2rem;
            font-style: italic;
            margin: 0;
            &:before {
            content: '"';
            }
            &:after {
            content: '"';
            }
        }
    }
    .conversation-message {
        @media screen and (min-width: 29rem) {
            display: flex;
        }
        .conversation-message-author-avatar {
            width: 77px;
            height: 77px;
            display: block;
            border-radius: 50%;
        }
        .conversation-message-contents {
            @media screen and (min-width: 29rem) {
                margin-left: $single-space * 3;
                flex: 1 0 0%;
            }
        }
        .conversation-message-author-name {
            @extend .text-body;
            @extend .text-body--bold;
            margin-top: $single-space * 2;
            margin-bottom: 0;
        }
    }
    .twitter-tweet-rendered {
        margin: 0 auto $single-space * 2 !important; // override twitter inline styles
    }
    .wistia_responsive_padding {
        margin-bottom: $single-space * 4;
    }
}

#reading-time {
    color: $grey-mid;
    &:before {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 0.5rem;
        vertical-align: text-bottom;
        background-size: 100%;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTVDNi42MTU1MyAxNSA1LjI2MjE2IDE0LjU4OTUgNC4xMTEwMSAxMy44MjAzQzIuOTU5ODcgMTMuMDUxMSAyLjA2MjY2IDExLjk1NzkgMS41MzI4NSAxMC42Nzg4QzEuMDAzMDMgOS4zOTk3IDAuODY0NDEgNy45OTIyNCAxLjEzNDUxIDYuNjM0MzdDMS40MDQ2IDUuMjc2NSAyLjA3MTI5IDQuMDI5MjIgMy4wNTAyNiAzLjA1MDI2QzQuMDI5MjIgMi4wNzEyOSA1LjI3NjUgMS40MDQ2IDYuNjM0MzcgMS4xMzQ1MUM3Ljk5MjI0IDAuODY0NDEgOS4zOTk3IDEuMDAzMDMgMTAuNjc4OCAxLjUzMjg1QzExLjk1NzkgMi4wNjI2NiAxMy4wNTExIDIuOTU5ODcgMTMuODIwMyA0LjExMTAxQzE0LjU4OTUgNS4yNjIxNiAxNSA2LjYxNTUzIDE1IDhDMTUgOS44NTY1MiAxNC4yNjI1IDExLjYzNyAxMi45NDk3IDEyLjk0OTdDMTEuNjM3IDE0LjI2MjUgOS44NTY1MiAxNSA4IDE1Wk04IDJDNi44MTMzMiAyIDUuNjUzMjggMi4zNTE5IDQuNjY2NTggMy4wMTExOUMzLjY3OTg5IDMuNjcwNDcgMi45MTA4NSA0LjYwNzU1IDIuNDU2NzMgNS43MDM5QzIuMDAyNiA2LjgwMDI2IDEuODgzNzggOC4wMDY2NiAyLjExNTI5IDkuMTcwNTRDMi4zNDY4IDEwLjMzNDQgMi45MTgyNSAxMS40MDM1IDMuNzU3MzYgMTIuMjQyNkM0LjU5NjQ4IDEzLjA4MTggNS42NjU1OCAxMy42NTMyIDYuODI5NDYgMTMuODg0N0M3Ljk5MzM1IDE0LjExNjIgOS4xOTk3NSAxMy45OTc0IDEwLjI5NjEgMTMuNTQzM0MxMS4zOTI1IDEzLjA4OTIgMTIuMzI5NSAxMi4zMjAxIDEyLjk4ODggMTEuMzMzNEMxMy42NDgxIDEwLjM0NjcgMTQgOS4xODY2OSAxNCA4QzE0IDYuNDA4NyAxMy4zNjc5IDQuODgyNTggMTIuMjQyNiAzLjc1NzM2QzExLjExNzQgMi42MzIxNCA5LjU5MTMgMiA4IDJaIiBmaWxsPSIjNUY2MzY4Ii8+CjxwYXRoIGQ9Ik0xMC4yOTUgMTFMNy41IDguMjA1VjMuNUg4LjVWNy43OUwxMSAxMC4yOTVMMTAuMjk1IDExWiIgZmlsbD0iIzVGNjM2OCIvPgo8L3N2Zz4=");
    }
}
