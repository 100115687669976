.quote-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $single-space * 10;
    @media screen and (max-width: 34rem) {
        padding: $single-space * 6 $single-space * 4;
    }
    blockquote {
        @media screen and (min-width: 58rem) {
            flex: 0.5;
        }
    }
}