.client-tab-card-wrapper {
    display: flex;
    max-width: $site-wrapper-max-width;
    margin: 0 auto;
    padding-left: $single-space * 6;
    padding-right: $single-space * 6;
    @media screen and (max-width: $breakpoint-tablet) {
       flex-direction: column;
       height: auto;
       padding: 0px $single-space * 2 0px;
    }
}
.client-tab-card-content {
      position: relative;
      padding: ($single-space * 6) ($single-space * 6) ($single-space * 10) ($single-space * 6);
      width: 60%;
      img {
          position: absolute;
          bottom: $single-space * 5;
          right: $single-space * 6;
          width: 555px;
      }

      @media screen and (max-width: $breakpoint-tablet) {
        position: relative;
        padding: ($single-space * 2) ($single-space * 1) ($single-space * 2) ($single-space * 1);
        width: 100%;
        .header-medium {
              font-size: 1.52rem; //25px
              line-height: 2rem; //32px
        }
      }
  }
  .client-tab-card-images {
      padding-top: $single-space * 6;
      margin-bottom: $single-space * 20;
      width: 40%;
      @media screen and (max-width: $breakpoint-tablet) {
        margin-bottom: auto;
        padding-top: 0px;
        width: 100%;
      }
  }
 .client-tab-card-logo {
      padding-bottom: $single-space * 4;
      width: 60%;
      @media screen and (max-width: $breakpoint-tablet) {
        padding-bottom: $single-space * 2;
        width: 100%;
      }
 }
.client-tab-card-caption {
       padding-top: $single-space * 5;
       padding-bottom: $single-space * 3;
       @media screen and (max-width: $breakpoint-tablet) {
           padding-top: $single-space * 2;
           padding-bottom: $single-space * 2;
       }
}