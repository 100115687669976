.case-study-hero {
    margin-bottom: $single-space * 4;
}
.case-study-hero-content {
    margin-top: $single-space * 10;
    margin-bottom: $single-space * 10;
}
.case-study-hero-media {
    max-height: 510px;
    overflow: hidden;
    position: relative;
}
.case-study-hero-video-background {
    padding-top: 43%;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(50, 52, 54, 0.8);
    }
    &:focus::before {
        border: 4px solid #148AFF;
        box-sizing: border-box;
    }
    &:hover::before {
        background: rgba(28, 30, 32, 0.95);
    }
    &:hover {
        .watch-video-transparent-icon {
            display: none;
        }
        .watch-video-solid-icon {
            display: inline-block;
            opacity: 1;
        }
        .watch-video-text {
            display: inline-block;
        }
    }
}
.case-study-play-container {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    cursor: pointer;
    transform: translate(-50%, -50%);
    width: 188px;
    height: 68px;
    align-items: center;
    justify-content: center;
    &:focus {
        outline: 2px solid#148AFF;
        box-sizing: border-box;
    }
}
.watch-video-transparent-icon {
    display: inline-block;
}
.watch-video-solid-icon {
    display: none;
    padding-right: $single-space;
}
.watch-video-text {
    display: none;
    width: 138px;
    height: 28px;
    text-align: center;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.290909px;
    text-decoration-line: underline;
    color: #FFFFFF;
}