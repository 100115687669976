div.api-docs {
	padding: 2rem;
	font-size: 0.875rem;
	max-width: 50rem;
	margin: 0 auto;
}

div.api-docs h2:not(:first-of-type) {
	margin-top: 3rem;
	border-top: 1px solid #eee;
	padding-top: 2.5rem;
}

div.api-docs h3,
div.api-docs h4 {
	font-weight: 700;
	margin: 1em 0;
}

div.api-docs pre,
div.api-docs code,
div.api-docs samp {
	font-family: "Consolas", "Lucida Console", "monaco", monospace;
	font-size: 0.75rem;
}

div.api-docs code {
	white-space: nowrap;
}

div.api-docs samp {
	display: block;
	margin: 1rem 0;
	padding: 1rem;
	border: 1px solid #ccc;
	background-color: #eee;
	border-radius: 0.5rem;
	overflow-x: auto;
}

div.api-docs samp pre {
	margin: 0;
}

div.api-docs div.example header {
	background-color: #3c4257;
	color: #a3acb9;
	padding: 0.75rem 1rem;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	background-repeat: no-repeat;
	background-size: 1.5rem 1.5rem;
	background-position: center right 0.75rem;
	background-image: url(/images/work/lifetime-income-api/clipboard_copy.svg);
	cursor: pointer;
}

div.api-docs div.example header.copied {
	background-image: url(/images/work/lifetime-income-api/clipboard_check.svg);
}

div.api-docs div.code {
	padding: 0.75rem 1rem;
	background-color: #4f566b;
	color: #f5fbff;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	overflow-x: auto;
}

div.api-docs div.code code {
	display: block;
	counter-increment: listing;
}

div.api-docs div.code code::before {
	content: counter(listing);
	display: inline-block;
	text-align: right;
	width: 1.5rem;
	padding-right: 0.5rem;
	border-right: 1px solid #697386;
	margin-right: 0.5rem;
	color: #697386;
}

div.api-docs div.code code pre {
	margin-left: 1rem;
	display: inline;
}

div.api-docs div.code code pre.prompt {
	margin-left: 0;
}

div.api-docs div.code code pre.prompt::before {
	content: "$";
	display: inline-block;
	width: 1rem;
}

div.api-docs ul {
	padding-inline-start: 40px;
}

div.api-docs li {
	margin: 1rem 0;
}

div.api-docs nav {
	margin: 1rem 0;
}

div.api-docs nav li {
	margin: 0;
	padding: 0;
}

div.api-docs table {
	border-collapse: collapse;
}

div.api-docs th {
	text-align: left;
	background-color: #f5f5f5;
}

div.api-docs th,
div.api-docs td {
	border: 1px solid #ccc;
	padding: 0.5rem 1rem;
}

div.api-docs table.lifetime-income-statement th:first-child {
	width: 25%;
}

div.api-docs table.lifetime-income-statement th:nth-child(2) {
	width: 30%;
}

div.api-docs div.label-and-input {
	display: flex;
	align-items: baseline;
	margin: 1rem 0;
}

div.api-docs div.label-and-input label,
div.api-docs div.label-and-input select,
div.api-docs div.label-and-input input {
	display: block;
}

div.api-docs label {
	margin-right: 0.5rem;
}

div.api-docs select,
div.api-docs input {
	font: inherit;
	padding: 0.25rem 1rem;
	border: 1px solid #ccc;
	border-radius: 0.25rem;
	/* width: 12rem; */
}

div.api-docs input {
	text-align: right;
}

div.api-docs p {
	margin: 1rem 0;
}

div.api-docs a {
	color: #1574D4;
}

div.api-docs a:hover {
	text-decoration: underline;
}

#copy {
	position: absolute;
	top: -10rem;
	left: -10rem;
	width: 1rem;
	height: 1rem;
	opacity: 0;
	-webkit-appearance: none;
	appearance: none;
	border: none;
}