.footer-global {
  ul {
    list-style: none;
  }
  h3 a {
    color: $white;
  }
}

.footer-global-wrapper {
  display: flex;
  padding: $single-space * 8 0;
  @media screen and (max-width: 25rem) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  > svg {
    margin-right: $single-space * 6;
    @media screen and (max-width: 25rem) {
      margin: 0 0 $single-space * 6 0;
    }
  }
  > div {
    flex: 1 0 0%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 77rem) {
      justify-content: space-around;
    }
    @media screen and (max-width: 50rem) {
      justify-content: flex-start;
      section {
        margin-bottom: $single-space * 6;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    > section {
      width: 22%;
      @media screen and (max-width: 77rem) {
        width: 44%;
      }
      @media screen and (max-width: 50rem) {
        width: 65%;
      }
      @media screen and (max-width: 33rem) {
        width: 100%;
      }
    }
  }
}
.footer-social {
  h2 {
    margin-bottom: $single-space;
  }
  a {
    color: $blue;
  }
  // hub spot styling
  .hbspt-form {
    margin-bottom: $single-space * 2;
  }
  .hs_email {
      width: 85%;
      label {
        display: none;
      }
      input {
          background-color: $grey-xdark;
          border-right: none;
          width: 100%;
          font-size: 1rem;
          color: $white;
          height: 57px;
      }
  }
  .hs_submit {
      width: 15%;
      height: 55px;
      position: relative;
      &:after {
        content: '';
        display: block;
        width: 9px;
        height: 16px;
        background: url(/images/chevron-right.svg);
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
      }
      input {
          width: 100%;
          border: 1px solid $grey-light;
          border-left: none;
          border-radius: 0;
          height: 57px;
          padding: 0;
          background-color: transparent;
          color: $white;
          font-size: 1rem;
          font-weight: 700;
          -webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          position: absolute;
          left: 0;
      }
  }
  .hs-form {
      display: flex;
      position: relative;
  }
  .hs_error_rollup {
    position: absolute;
    bottom: -22px;
    font-size: 0.875rem;
  }
}
.footer-links {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 25rem) {
    flex-direction: column;
    div:first-child {
      margin-bottom: $single-space * 6;
    }
  }
  a:hover {
    color: $white;
  }
  a:visited {
    color: #9575CD;
  }
}
.footer-contact {
  address {
    a:hover {
      color: $white;
    }
    a:visited {
      color: #9575CD;
    }
  }
}
.footer-copyright {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: $single-space * 2 0;
  @media screen and (max-width: 58rem) {
    flex-direction: column;
    .cantina-logo-link {
      margin-right: $single-space;
      @media screen and (max-width: 40rem) {
        margin-bottom: $single-space;
      }
    }
  }
  div {
    align-items: center;
    display: flex;
    @media screen and (max-width: 58rem) {
      margin-bottom: $single-space;
    }
    @media screen and (max-width: 40rem) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
  p, span, a {
    margin-bottom: 0;
    @media screen and (max-width: 40rem) {
      font-size: 12px;
      a {
        font-size: 12px;
      }
    }
  }
}
