.sprint-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: $single-space * 6 0;
  flex-wrap: wrap;
  @media screen and (max-width: 60rem) {
    padding-bottom: $single-space * 3;
  }
  @media screen and (max-width: 37rem) {
    padding: $single-space * 3
  }
}
.sprint-card {  
  display: flex;
  flex-direction: column;
  justify-content: left;
  background-color: $white;
  padding: $single-space * 2;
  width:17%;
  overflow-wrap: normal;
  @media screen and (max-width: 60rem) {
    width: 31%;
    margin-bottom: $single-space * 3;
  }
  @media screen and (max-width: 37rem) {
    width: 45%;
    margin-bottom: $single-space * 3;
  }
  @media screen and (max-width: 37rem) {
    width: 100%;
    margin-bottom: $single-space * 3;
  }
}

.sprint-card-number {
  margin-bottom: $single-space *3;
}

.sprint-card-title {
 margin-bottom: $single-space;
}


