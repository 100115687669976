$hamburger-breakpoint: 77rem;

.g-global-nav {
    width: 100%;
    align-items: center;
    display: flex;
    padding-top: $single-space * 4;
    z-index: 1000;
    flex: 0 0 auto;

    .g-global-nav-menu {
        align-items: center;
        display: flex;
        margin: 0 0 0 auto;
        list-style-type: none;
        left: 0;

        @media screen and (max-width: $hamburger-breakpoint) {
          display: block;
          position: absolute;
          -webkit-transform: translate(0, -100%);
          transform: translate(0, -100%);
          width: 100%;
          min-height: 100vh;
          text-align: center;
          padding: 4rem 4rem 6rem 4rem;
          top: 0;
          background: $white;
          transition: transform .2s ease;
          margin: 0;

          &.nav-open{
            -webkit-transform: translate(0, 0%);
            transform: translate(0, 0%);
            background: $grey-xdark;
            display: flex;
            flex-direction: column;
            z-index: 1;
            .g-global-nav-item {
              width: 100%;
            }
          }
        }
        @media screen and (max-width: $bp-tiny) {
          padding: 4rem $single-space * 2;
        }
        @media screen and (max-width: 26rem) {
          justify-content: space-between;
          .g-global-nav-reach-out {
            width: 100%;
          }
        }
    }

    .g-global-nav-logo {
        transition: opacity $r-transition-default;
        vertical-align: middle;
        &:hover {
            opacity: 0.5;
        }

        &:active {
            opacity: 1;
            transition: none;
        }
    }
    .g-global-nav-control {
      padding: 4px 0;
      position: absolute;
      right: 0;
      z-index: 20;
      right: $alpha;
      cursor: pointer;

      @media screen and (min-width: $hamburger-breakpoint) {
          display: none;
      }
      @media screen and (max-width: $hamburger-breakpoint) {
        display: block;
      }
      .patty {
        display: block;
        width: 27px;
        height:2px;
        margin: 4px 0;
        background: $white;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        position: relative;
        &.dark {
          background: $grey-xdark;
        }
      }

      .patty:after,
      .patty:before {
        content: '';
        display: block;
        width: 27px;
        height: 2px;
        background: $white;
        position: absolute;
        transition: all 0.3s;
      }
      .patty.dark:after,
      .patty.dark:before {
        background: $grey-xdark;
      }

      .patty:before {
        top: -8px;
      }
      .patty:after {
        top: 8px;
      }

      .menu-open.patty {
        background: none;
      }


      .menu-open.patty:before {
        top: 0;
        -webkit-transform: rotate(45deg)
      }

      .menu-open.patty:after {
        top: 0;
        -webkit-transform: rotate(-45deg)
      }
    }
    .g-global-nav-item {
        margin-left: 0.2rem;
        padding: 0;
        letter-spacing: 1px;
    }

    .g-global-nav-link {
        padding: $single-space * 2 $single-space * 2 $single-space $single-space * 2;
        color: #ffffff;
        font-size: 0.875rem;
        text-decoration: none;
        text-transform: uppercase;

        transition: all $r-transition-default;

        @media screen and (max-width: $hamburger-breakpoint) {
          display: inline-block;
          font-size: 1.125rem;
          margin-bottom: $beta;
          padding-bottom: $kappa;
        }
        @media screen and (max-width: $bp-tiny) {
          display: inline-block;
          font-size: 1.125rem;
          margin-bottom: $single-space;
          padding-bottom: 0;
        }
        &:hover {
            color: $grey-light;
            border-bottom: 2px solid $blue;
        }

        &:active {
            opacity: 1;
            transition: none;
        }
    }

    .g-global-nav-active {
        border-bottom: 2px solid $blue;
        @media screen and (max-width: $hamburger-breakpoint) {
          opacity: 1;
          border-bottom: 3px solid $orange;
        }
    }

    .g-global-nav-reach-out {
      margin-left: $single-space * 2;
      padding: $single-space * 2 $single-space * 4;
      background-color: $blue-mid;
      transition: background-color .15s;
      border-radius: 2px;
      &:hover,
      &:focus {
          background-color: $blue-dark;
          color: $white;
          opacity: 1;
          border: none;
      }
      @media screen and (max-width: 100rem) {
        margin-left: 0;
      }
      @media screen and (max-width: 1070px) {
        padding: $single-space * 2 1.7rem;
      }
      @media screen and (max-width: $bp-tiny) {
        margin-left: 0;
        padding: $single-space * 2 $single-space * 4;
      }
    }
    &.g-global-nav-menu--on-white-bg {
      background-color: $white;
      .g-global-nav-link {
        color: $grey-xdark;
      }
      .g-global-nav-active  {
        opacity: 1;
        border-bottom: 2px solid $blue;
      }
      .g-global-nav-reach-out {
        color: $white;
      }
      @media screen and (max-width: $hamburger-breakpoint) {
        .g-global-nav-link {
          color: $white;
        }
      }
    }
}
