
.employee-photo-banner {
  display:flex;
  justify-content:space-between;
  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction:column;
    margin-bottom: 40px;
  }

  .center-image {
    margin: 0px 16px;
    @media screen and (max-width: $breakpoint-mobile) {
      margin: 16px 0px;
    }
  }
}
