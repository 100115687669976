.newsletter-signup-banner {
    display: flex;
    padding: $single-space * 10;
    align-items: center;
    @media screen and (max-width: 80rem) {
        padding: $single-space * 10 $single-space * 4;
    }
    @media screen and (max-width: 63rem) {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: $single-space * 10 $single-space * 6;
        max-width: 650px;
    }
    @media screen and (max-width: 39rem) {
        margin: 0;
    }
    @media screen and (max-width: 32rem) {
        padding: $single-space * 10 $single-space * 4;
    }
    > div {
        flex: 1 0 0%;
        &:first-child {
            padding-right: $single-space * 10;
            @media screen and (max-width: 80rem) {
                padding-right: $single-space * 4;
            }
            @media screen and (max-width: 63rem) {
                padding-right: 0;
                margin-bottom: $single-space * 8;
            }
            @media screen and (max-width: 32rem) {
                margin-bottom: $single-space * 5;
            }
            p {
                margin-bottom: 0;
            }
        }
        &:last-child {
            padding-left: $single-space * 10;
            @media screen and (max-width: 80rem) {
                padding-left: $single-space * 4;
            }
            @media screen and (max-width: 63rem) {
                padding-left: 0;
            }
            p {
                margin-top: $single-space * 2;
                margin-bottom: 0;
                line-height: 24px;
                @media screen and (max-width: 63rem) {
                    margin-top: $single-space * 8;
                }
                @media screen and (max-width: 32rem) {
                    margin-top: $single-space * 3;
                }
            }
        }
    }
    // hub spot styling
    .hs_email {
        width: 75%;
        @media screen and (max-width: 32rem) {
            width: 100%;
            margin-bottom: $single-space * 2;
        }
        input {
            width: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        label {
            margin-bottom: 0;
        }
    }
    .hs-submit {
        width: 25%;
        margin-top: 27px; // Adding this to align the button with the input, now that there is a proper label 
        @media screen and (max-width: 32rem) {
            width: 100%;
        }
        input {
            width: 100%;
            height: 40px;
            appearance: none;
            -webkit-appearance: none;
            border-radius: 0;
            padding: 0.595rem 1rem;
        }
    }
    .hs-form {
        display: flex;
        @media screen and (max-width: 32rem) {
            flex-direction: column;
        }
    }
    .hs-button {
        @extend .button;
        padding: 1.125rem 0;
    }
    .muted-text {
        color: $grey-mid;
    }
}