.image-header-card-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 48rem) {
        justify-content: space-around;
    }
}
.image-header-card {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $single-space * 4;
    width: 25%;
    @media screen and (max-width: 48rem) {
        width: 46%;
    }
    img {
        max-width: 150px;
    }
}