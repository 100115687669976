.category-controls-wrapper {
    border-bottom: 1px solid $grey-light;
    padding-bottom: $single-space * 4;
}
.category-controls {
    list-style: none;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    @media screen and (max-width: 82.5rem) {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    li {
        @media screen and (max-width: 82.5rem) {
            margin: 0 $single-space $single-space 0;
            padding: 0;
        }
    }
    button, a {
        padding: $single-space 15px;
        border: none;
        background: none;
        display: block;
        &.active {
            background: $grey-xdark;
            color: $white;
        }
        @media screen and (max-width: 82.5rem) {
            background: $grey-xdark;
            color: $white;
            border: 2px solid transparent;
            &.active {
                background: $white;
                color: $grey-xdark;
                border: 2px solid $grey-xdark;
            }
        }
    }
}
.page-blog {
    .category-controls-wrapper {
        border-bottom: none;
    } 
}