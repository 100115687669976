.scenario-container {
    display: flex;
    flex-wrap: wrap;
    .scenario-item {
        width: 25%;
        padding-right: $single-space * 4;
        &:last-child {
            padding-right: 0;
        }
        @media screen and (max-width: 1056px) {
            width: 50%;
            margin-bottom: $single-space * 4;
            &:last-child {
                margin-bottom: 0;
            }
            &:nth-child(2n) {
                padding-right: 0;
            }
        }
        @media screen and (max-width: 550px) {
            width: 100%;
            padding-right: 0;
        }
    }
}
.scenario-item-image-container {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px;
    background-color: $blue-navy;
    margin-bottom: $single-space * 4;
}