.content-image-banner {
    align-items: center;
    padding-right: 0;
    display: flex;
    @media screen and (max-width: 37.5rem) {
        flex-direction: column-reverse;
        padding: 0;
    }
    > div {
        flex: 1 0 0%;
        &:first-child {
            padding: 0 $single-space * 10;
            @media screen and (max-width: 58rem) {
                padding: 0 5rem;
            }
            @media screen and (max-width: 37.5rem) {
                padding: $single-space * 6 $single-space * 4;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
        &:last-child {
            height: 323px;
            img {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }
    }
}