.h-clients {
    padding: ($single-space * 4) 0;
    background-color: $grey-xlight;

    .h-clients-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;

        > * {
            margin-right: $single-space * 8;

            @media screen and (max-width: 80rem) {
                margin-right: $single-space * 5;
            }

            @media screen and (max-width: 68rem) {
                margin-right: $single-space * 4;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        // fixes issue preventing svgs from shrinking
        * {
            min-width: 0;
        }

        .intel { width: 80px; }
        .bose { width: 175px; }
        .avid { width: 130px; }
        .bcbsma { width: 230px; }
        .syncthink { width: 128px; }
        .schwab { width: 65px; }

        .cta {
            width: 60px;
            flex-shrink: 0;
            color: $grey-mid;
            font-size: $eta;
            letter-spacing: 0.2em;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;

            @media screen and (max-width: 68rem) {
                font-size: 0.625rem;
            }
        }
    }

    // Breakpoints
    @media screen and (max-width: $breakpoint-tablet) {
        padding: 0;

        .content-wrapper {
            padding: 0;
        }

        .h-clients-wrapper {
            justify-content: center;

            > *:not(:last-child) {
                display: none;
            }
    
            .cta {
                display: block;
                width: 100%;
                font-size: 1rem;
                padding: ($single-space * 3) 0;
            }
        }
    }
}
