.winner-box {
    display: flex;
    @media screen and (max-width: $breakpoint-tablet) {
        display: none;
    }
}
.winner-box-mobile {
    display: none;
    @media screen and (max-width: $breakpoint-tablet) {
        display: flex;
    }
}
.crosshairs-content {
    padding-top: $single-space * 6;
}
.crosshairs-box {
    display: flex;
    @media screen and (max-width: $breakpoint-tablet) {
        display: none;
    }
}
.crosshairs-box-mobile {
    display: none;
    @media screen and (max-width: $breakpoint-tablet) {
        display: flex;
    }
}