.awards {
    display: flex;
    flex-wrap: wrap;
    li {
        width: 33.3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: $single-space * 4;
        &:nth-of-type(3n + 1) {
            align-items: flex-start;
        }
        &:nth-of-type(3n) {
            align-items: flex-end;
        }
    }
    .awards-content-container {
        max-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .awards-image-container {
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $single-space * 2;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    p {
        text-align: center;
    }
}