.large-heading-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    display: flex;
    flex-direction: column;
    height: 625px;
    @media screen and (max-width: 77em) {
        background-position: 80%;
    }
    @media screen and (max-width: 70em) {
        background-position: 70%;
    }
    @media screen and (max-width: 60em) {
        background-position: 65%;
    }
}
.large-heading-banner-wrapper {
    width: 100%;
}
.large-heading-banner-content {
    display: flex;
    flex-direction: column;
    padding-left: 1rem; //16px
    padding-top: $single-space * 12;
    @media screen and (max-width: $breakpoint-phone) {
        padding-top: $single-space;
        }
    h1 {
        @media screen and (max-width: $breakpoint-phone) {
            font-size: 3.8125rem; //61px
            line-height: 1.5rem; //24px
            margin-top: $single-space;
        }
    }
    h2 {
        @media screen and (max-width: $breakpoint-phone) {
            font-size: 2.375rem; //38px
            line-height: 1.2; //19px
            margin-top: $single-space;
            padding-bottom: $single-space * 2;
        }
    }
}
