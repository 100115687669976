.internal-hero {
    padding-top: $single-space * 5;
    @media screen and (max-width: 42rem) {
        padding-top: $single-space * 3;
        padding-bottom: $single-space * 3;
    }
    .internal-hero-container {
        .subheader {
            padding-right: $single-space * 12;
            @media screen and (max-width: 42rem) {
                padding-right: 0;
            }
        }
        @media screen and (min-width: 60rem) {
            display: flex;
            &--margin-left {
                flex-direction: column;
                width: 50%;
                margin-left: 25%;
            }
        }
        > div {
            flex: 1 0 0%;
        }
    }
    h2 {
        margin-top: -11px; // Align to cap height of paragraph text
        @media screen and (min-width: 60rem) {
            padding-right: $single-space * 8;
        }
    }
    .internal-hero-link-hidden-mobile {
        display: none;
        @media screen and (min-width: 60rem) {
            display: inline;
        }
    }
    .internal-hero-link-hidden-desktop {
        @media screen and (min-width: 60rem) {
            display: none;
        }
    }
}
