.post-share {
    margin-bottom: $single-space * 3;
    @media screen and (min-width: 56rem) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .social-share {
            a {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-bottom: $single-space;
                margin-right: 0;
                span {
                    display: inline;
                    margin-right: 5px;
                }
            }
        }
    }
    p {
        margin-bottom: $single-space;
    }
    a {
        span {
            display: none;
        }
    }
}
