
// Logic
$r-transition-default: 250ms ease-in-out;

%t-shadow {
    -webkit-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.15);
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.15);
}

// Global components
@import
    "_components/social-media",
    "_components/scroll-down",
    "_components/hero",
    "_components/content-banner",
    "_components/quote",
    "_components/quote-banner",
    "_components/card-feature-banner",
    "_components/card",
    "_components/secondary-card",
    "_components/tertiary-card",
    "_components/quaternary-card",
    "_components/cards-banner",
    "_components/secondary-cards-banner",
    "_components/call-out-banner",
    "_components/call-out-banner-no-padding",
    "_components/newsletter-signup-banner",
    "_components/questions-banner",
    "_components/contact-form",
    "_components/content-image-banner",
    "_components/centered-content-banner",
    "_components/internal-page-hero",
    "_components/team-profile",
    "_components/dictionary-list-item",
    "_components/image-header-card",
    "_components/left-content-banner",
    "_components/events-card",
    "_components/events-list",
    "_components/event",
    "_components/profile",
    "_components/profile-card",
    "_components/social-links",
    "_components/instagram",
    "_components/internal-page-card-hero",
    "_components/social-share",
    "_components/category-controls",
    "_components/profile-image-name-title",
    "_components/post-share",
    "_components/post-category-buttons",
    "_components/post-footer",
    "_components/percent-stats",
    "_components/swipers",
    "_components/sprint-card",
    "_components/case-study-hero",
    "_components/callout-table",
    "_components/learn-more",
    "_components/content-image-large",
    "_components/awards",
    "_components/content-call-out",
    "_components/case-study-share",
    "_components/scenario",
    "_components/page-metadata-sidebar",
    "_components/page-navigation-sidebar",
    "_components/clients",
    "_components/careers",
    "_components/global-nav",
    "_components/cantina-logo",
    "_components/download-app",
    "_components/content-container-50-50",
    "_components/content-image-50-50-banner",
    "_components/content-image-50-50-banner-full",
    "_components/logos",
    "_components/design-features",
    "_components/image-background-color",
    "_components/modal",
    "_components/table",
    "_components/large-heading-banner",
    "_components/configurable-content-image-banner",
    "_components/industry-content-image-promo-banner",
    "_components/content-image-banner-large",
    "_components/white-paper-signup-banner",
    "_components/client-tab-card",
    "_components/client-tabs",
    "_components/winners-and-crosshairs",
    "_components/review-carousel",
    "_components/lifetime-income-api"
;

// Blog
@import
    "_blog/blog"
;
// Bigfoot **vendor styles http://www.bigfootjs.com/
@import
    "_bigfoot/bigfoot-default.scss"
;
