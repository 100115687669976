.internal-card-hero {
    display: flex;
    &.left {
        flex-direction: row-reverse;
    }
    @media screen and (max-width: 65rem) {
        flex-direction: column;
        &.left {
            flex-direction: column;
        }
    }
}
.internal-card-hero-img-container {
    width: 64%;
    position: relative;
    flex-grow: 1;
    @media screen and (max-width: 65rem) {
        width: 100%;
    }
    img {
        display: block;
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
    }
    .internal-card-hero-date {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $grey-xdark;
        padding: $single-space $single-space * 2;
        color: $white;
    }
}
.internal-card-hero-content {
    width: 36%;
    padding: $single-space * 3 0 $single-space * 3 $single-space * 6;
    flex-grow: 1;
    .left & {
        padding: $single-space * 3 $single-space * 6 $single-space * 3 0;
    }
    @media screen and (max-width: 65rem) {
        padding: $single-space * 3 $single-space * 3 $single-space * 3 0 ;
        width: 100%;
    }
    h2 {
        margin-bottom: 0;
    }
    .author {
        display: block;
        margin: $single-space 0 $single-space * 2;
    }
}
.internal-card-hero-social-share {
    margin-top: $single-space * 4;
}
