.event {
    display: flex;
    @media screen and (max-width: 56rem) {
        display: inline;
        .callout-table {
          max-width: none;
          width: 100%;
          margin-top: $single-space * 5;
        }
        .button-event-register {               
            display:none;
        }
        .button-event-register-mobile {  
            display:block;
        }
      }
}
.button-event-register-mobile {               
    display:none;
    max-width: none;           
    width:100%;
    text-align: center;
}

.event-content {
    flex: 1 0 0%;
    padding-right: $single-space * 8;
    > .button {
        margin-top: $single-space * 8;
    }
    p {
        a {
            color: $blue !important; //overwrite inline markdown styles
        }
    }
    @media screen and (max-width: $breakpoint-mobile) {
        padding:0px;
    }
    ul { 
        list-style-position: inside;
    }
}
.event-share {
    list-style: none;
    display: flex;
    justify-content: space-around;
    svg {
        width: 24px;
        height: 24px;
        path {
        fill: $grey-mid;
        }
    }
}
