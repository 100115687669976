.events-list-container {
    flex: 1 0 0%;
    .content-banner-header {
        @media screen and (max-width: 39rem ) {
            padding-left: $single-space * 2;
        }
    }
    .content-wrapper {
        @media screen and (max-width: 39rem ) {
            padding: 0;
        }
    }
}

.events-list-controls {
    .category-controls {
        justify-content: flex-start;
        @media screen and (min-width: 60rem) {
            margin-left: 25%;
        }
    }
}
.events-list-no-events {
    display: none;
    padding: 0 20px 80px;
}