.header-xlarge {
  font-size: 5.0rem; //80px
  letter-spacing: 1px;
  line-height: 5.3125rem; //85px
  margin-bottom: $single-space * 4;
  &--reverse {
    color: $white;
  }
 }
.header-xlarge-condensed {
    font-size: 5.0rem; //80px
    letter-spacing: 1px;
    line-height: 5.0rem; //80px
    margin-bottom: $single-space * 4;
    &--reverse {
      color: $white;
    }
    &--bold {
       font-weight: $weight-bold;
    }
}
.header-large {
  font-size: 3.8125rem; //61px
  letter-spacing: 1px;
  line-height: 4.625rem; //74px
  margin-bottom: $single-space * 4;
  &--reverse {
    color: $white;
  }
}
.header-medium-large {
  font-size: 2.5rem; //40px
  letter-spacing: 1px;
  line-height: 3rem; //48px
  margin-bottom: $single-space * 4;
  &--reverse {
    color: $white;
  }
}
.header-medium-large-condensed {
  font-size: 2.5rem; //40px
  letter-spacing: 1px;
  line-height: 2.5rem; //40px
  margin-bottom: $single-space * 1;
  &--reverse {
    color: $white;
  }
}
.header-medium {
  font-size: 1.95rem; //31.25px
  line-height: 2.8125rem; //38px
  margin-bottom: $single-space * 2;
  &--reverse {
    color: $white;
  }
  &--green {
      color: $green;
  }
  &--regular {
    font-weight: $weight-regular;
  }
}
.header-small {
  font-size: 1.52rem; //25px
  line-height: 2rem; //32px
  margin-bottom: $single-space * 2;
  &--reverse {
    color: $white;
  }
  &--green {
      color: $green;
  }
}
.header-extra-small {
  font-size: 1.25rem; //20px
  line-height: 1.5;
  margin-bottom: $single-space * 2;
  &--reverse {
    color: $white;
  }
  &--bold {
    font-weight: $weight-bold;
  }
  &--centered {
    text-align: center;
  }
  &--quote {
    font-family: 'Merriweather', serif;
    font-style: italic;
    &:before {
      content: '"';
    }
    &:after {
      content: '"';
    }
  }
  &--no-margin {
    margin-bottom: 0
  }
}
.text-uppercase {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1rem;
    &--reverse {
      color: $white;
    }
    &--bold {
      font-weight: $weight-bold;
    }
    &--underline {
      border-bottom: 2px solid $blue;
      display: inline-block;
      padding-bottom: $single-space;
      line-height: 1;
    }
    &--small {
      font-size: 0.875rem;
    }
    &--italic {
      font-style: italic;
    }
}
.text-body {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: $single-space * 4;
  overflow-wrap: anywhere;
  &--reverse {
    color: $white;
    a:visited {
      color: #9575CD
    }
  }
  &--blue {
    color: $blue;
  }
  &--green {
      color: $green;
  }
  &--light {
    font-weight: $weight-light;
  }
  &--bold {
    font-weight: $weight-bold;
  }
  &--italic {
    font-style: italic;
  }
  &--xlarge--condensed {
    font-size: 3.815rem; //61.04px
    letter-spacing: 1px;
    line-height: 4.625rem; //74px
    margin-bottom: $single-space * 2;
    }
  &--large {
    font-size: 1.375rem;
  }
  &--large-condensed {
    line-height: 1rem;
    margin-bottom: $single-space * 0.5;
  }
  &--medium {
    font-size: 1rem;
    line-height: 1.5;
  }
  &--medium-condensed {
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: $single-space * 0.5;
  }
  &--small {
    font-size: .875rem;
    line-height: 1.5;
  }
  &--xsmall {
    font-size: 0.8rem;
    line-height: 3.0;
  }
  &--link {
    color: $blue-mid;
    &:hover {
      text-decoration: underline;
    }
    &:visited {
      color: #4527A0;
  }
}
  &--link-with-arrow {
    display: inline-block;
    svg {
      margin-left: 10px;
      transition: margin-left .15s;
    }
    &:hover  {
      svg {
        margin-left: 20px;
      }
      text-decoration: underline;
    }
    &:visited {
      svg polygon {
        fill: #4527A0;
      }
    }
  }
  &--extra-margin-bottom {
    margin-bottom: $single-space * 7
  }

  &--link-with-arrow-down {
    background-image: url(/images/blue-arrow-down.svg);
    background-repeat: no-repeat;
    transition: padding-bottom .15s;
    padding-right: $single-space * 2;
    &:hover  {
      padding-right: $single-space * 2;
      padding-bottom: 10px;
    }
  }
  &--uppercase {
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  &--no-margin {
    margin-bottom: 0
  }
  &--no-top-margin {
    margin-top: 0
  }
}
.muted-text {
  color: $grey-light;
}
.bullet-list {
  list-style-type: disc;
}
.bullet-list, .number-bullet-list {
  margin-left: $single-space * 2;
  margin-bottom: $single-space * 4;
  li {
    padding-left: 8px;
    padding-bottom: $single-space;
    margin-bottom: 0;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5;
    a {
      color: $blue;
      &:hover {
        text-decoration: underline;
      }
    }
    ul, ol {
      margin-bottom: 0;
      margin-top: $single-space;
    }
  }
}
.link-color-override a{
  color: $blue;
  &:hover {
    text-decoration: underline;
  }
}
