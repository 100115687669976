.case-study-share {
    position: relative;
    .social-share {
        position: absolute;
        right: 0;
        top: $single-space * 12;
        border: 1px $grey-xlight solid;
        padding: 16px 16px 0px 16px;
        a {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 16px;
            margin-right: 0px;
            @media screen and (max-width: 49rem){
                display: flex;
                margin: 0px 16px 16px 16px;
                padding: 16px;
                background-color: $grey-xlight;
                border-radius: 100%;
            }
        }
        @media screen and (max-width: 49rem){
            position: unset;
            display: flex;
            justify-content: center;
            border:none;
            text-align:center;
            padding-top: 0px;
        }
    }
    @media screen and (max-width: 49rem){
        position: unset;
        display: block;  
    }
}
