.industry-content-image-promo-banner {
    display: flex;
    .industry-promo-text {
        padding-top: 5%;
        width: 57%;
        padding-left: 5%;
        padding-right: 10%;
        @media screen and (max-width: $breakpoint-phone) {
            padding: 7% 7% 0px 7%;
            width: 100%;
        }
    }
    .industry-promo-image {
        width: 43%;
        img {
            height: 100%;
        }
        @media screen and (max-width: $breakpoint-phone) {
          margin: auto;
          width: 100%;
          height: 70%;
          object-fit: cover;
        }
    }
    @media screen and (max-width: $breakpoint-phone) {
        flex-direction: column;
        height: auto;
    }
}