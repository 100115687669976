.content-call-out {
    border: 1px solid $grey-light;
    max-width: 866px;
    margin: 0 $single-space * 2;
    @media screen and (min-width: 57rem) {
        margin: 0 auto;
    }
    &.content-call-out-inside-content {
        margin-bottom: $single-space * 4;
        margin-left: 0;
        margin-right: 0;
        @media screen and (min-width: 57rem) {
            margin-left: -$single-space * 10;
            margin-right: -$single-space * 10;
        }
    }
    > a {
        @media screen and (min-width: 44rem) {
            display: flex;
        }
        &:hover {
            .text-body--link-with-arrow {
              text-decoration: underline;
                svg {
                    margin-left: $single-space * 2;
                }
            }
        }
    }
    .content-call-out-image {
        height: 250px;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;
        @media screen and (min-width: 44rem) {
            width: 250px;
            min-height: 250px;
            height: auto;
        }
        img {
            flex-shrink: 0;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            transition: all .15s;
        }
    }
    .content-call-out-container {
        flex: 1 0 0%;
        padding: $single-space * 3 $single-space * 4;
        p:first-child {
            margin-bottom: 0;
        }
        p:last-child {
            margin-bottom: 0; 
        }
    }
}