.profile-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: $single-space * 5;
}
.profile {
    width: 22%;
    @media screen and (max-width: 64rem) {
        width: 31%;
    }
    @media screen and (max-width: 37.5rem) {
        width: 48%;
    }
    @media screen and (max-width: 28rem) {
        width: 100%;
    }
}
.profile-name {
    margin: $single-space 0 0 0;
    + p {
        line-height: 1;
    }
}