@function rem($size) {
  $remSize: $size / $font-base-size;
  @return #{$remSize}rem;
}

@mixin img-fluid($display: block) {
  max-width: 100%;
  height: auto;
  display: $display;
}

@mixin prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@mixin prefix-value($name, $argument) {
  #{$name}: -webkit-#{$argument};
  #{$name}: -ms-#{$argument};
  #{$name}: -moz-#{$argument};
  #{$name}: -o-#{$argument};
  #{$name}: #{$argument};
}

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }

    -webkit-animation: $animations;
    -moz-animation: $animations;
    -o-animation: $animations;
    animation: $animations;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin visuallyrevealed() {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
