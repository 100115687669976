.modal {
  position: absolute;
  margin: 1rem;
  padding: 3.5rem;
  top: 1rem;
  left: calc(50% - 1rem);
  width: calc(100% - 4rem);
  max-width: 700px;
  transform: translate(-50%, 0);
  background-color: #fff;
  @media (min-width: 50rem) {
    top: calc(50% - 1rem);
    transform: translate(-50%, -50%);
  }
}

.modal > iframe {
  width: 100% !important;
}

.modal a {
  color: $blue-mid;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(45, 46, 46, 0.5);
}

.modal-loading-indicator {
  margin: 0 auto;
  display: block;
  width: 50px;
  height: auto;
  animation: infinite-rotate 5s linear infinite;
}

.modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.25rem;
  width: 1rem;
  height: 1rem;
  border: 0;
  background: none;
  appearance: none;
  cursor: pointer;
  text-indent: -99999px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE2IDEuNEwxNC42IDAgOCA2LjYgMS40IDAgMCAxLjQgNi42IDggMCAxNC42IDEuNCAxNiA4IDkuNGw2LjYgNi42IDEuNC0xLjRMOS40IDggMTYgMS40eiIgZmlsbD0iIzVGNjM2OCIvPjwvc3ZnPg==');
  background-position: center center;
  background-repeat: no-repeat;
  &:hover {
    opacity: 0.5;
  }
}

@keyframes infinite-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
