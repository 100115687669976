.secondary-card-container {
    .img-container {
        height: 167px;
    }

    .card {
        padding: ($single-space * 2) ($single-space * 2) 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
