.quaternary-cards-banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.quaternary-card {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    @media screen and (max-width: 52rem) {
        width: 45%;
    }
    @media screen and (max-width: 36rem) {
        width: 100%;
    }
}