.content-image-large-container {
    max-width: 1059px;
    margin: 0 auto;
    .content-image-large-image-wrapper {
        display: flex;
        justify-content: center;
        max-height: 507px;
        overflow: hidden;
        img {
            height: 100%;
            object-fit: contain;
        }
    }
    figcaption {
        text-align: center;
        margin-top: $single-space * 1.5;
    }
}
