.learn-more {
    margin: 0 auto;
    @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
            text-align: center;
    }
    span {
        display: block;
        text-align: center;
        letter-spacing: 10px;
        font-size: 12px;
    }
}