.table-wrapper {
  margin-bottom: $single-space * 4;
  overflow: auto;
  table {
    font-family: $font-body;
    font-size: 1rem;
    min-width: 550px;
    border: 1px solid $grey-xlight;
    border-collapse: collapse;
    border-spacing: 0;
    thead tr {
      background-color: $blue;
      color: $white;
    }
    th,
    td {
      padding: 0.5rem;
    }
    tbody th {
      background-color: $grey-100;
    }
  }
  // credit https://adrianroselli.com/2020/11/under-engineered-responsive-tables.html
  &[role="region"][aria-labelledby][tabindex] {
    background: linear-gradient(to right, #fff 30%, rgba(255, 255, 255, 0)),
      linear-gradient(to right, rgba(255, 255, 255, 0), #fff 70%) 0 100%,
      radial-gradient(
        farthest-side at 0% 50%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(
          farthest-side at 100% 50%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-position: 0 0, 100%, 0 0, 100%;
    background-attachment: local, local, scroll, scroll;
  }
}

table {
  &.align-top tbody td {
    vertical-align: top;
  }

  &.border-dark {
    border-color: $grey-mid;

    th, td {
      border: 1px solid $grey-mid;
    }
  }

  p {
    margin-bottom: $single-space;
  }
}
