.questions-bannner {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 62rem) {
        flex-direction: column;
    }
    .button {
        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
            text-align: center;
        }
    }
}