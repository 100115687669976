.content-image-50-50-banner {
    display: flex;
    div:first-child {
        padding-right: 10%;
        @media screen and (max-width: 43rem){
            padding-right: 0;
        }
    }
    /*> div {
        flex: 1 0 0%;
    }*/
    > div img {
        /*width: 100%;
        height: auto;*/
        margin-bottom: $single-space * 4;
    }
    @media screen and (max-width: 43rem){
        flex-direction: column;
    }
}
