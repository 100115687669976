.sideBySideContainer {
  display: flex;

  @media screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
  }
}

.standaloneContainer {
  display: flex;
  justify-content: center;
  text-align: center;

  blockquote {
    &.blueVersion {

      &:before {
        @media screen and (max-width: $breakpoint-mobile) {
          left: calc(50% - 33.5px);
        }
      }

      footer {
        .text-body {
          display: inline;
          font-size: 1.375rem;
          font-style: italic;
          padding: 0;

          &:first-child:before {
            position: relative;
          }

          &:first-child:after {
            content: "\002C ";
            position: relative;
          }
        }
      }
    }
  }
}

blockquote {
  &.blueVersion {
    padding-left: 110px;
    position: relative;
    color: $blue-mid;
    min-height: 83px; // 67px svg height + 8px padding top and bottom
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:before {
      content: '';
      width: 67px;
      height: 67px;
      position: absolute;
      left: 0;
      top: 8px;
      background: url(/images/quote.svg) no-repeat;
    }
    p.quote-text {
      margin: 0;
        &:before {
        content: '"';
        }
        &:after {
        content: '"';
        }
    }
    footer {
      margin-top: $single-space * 4;
      .text-body {
        display: block;
        margin: 0;
        padding-left: $single-space * 2;
        position: relative;
        line-height: 1.5;
    
        &:first-child:before {
          content: "\2014 ";
          position: absolute;
          left: 0;
        }
      }
    }

    &.sideBySideQuote {
      width: 49%;
      display: inline-flex;
      padding: 110px 65px 0 65px;
      text-align: center;
      margin-bottom: 50px;

      &:before {
        left: calc(50% - 33.5px);
      }

      &.quote-left {
        border-right: 1px solid black;
      }

      footer {
        .text-body {
          display: inline-block;
          padding-left: 0px;

          &:first-child:before {
            position: relative;
          }
        }
      }
    }

    @media screen and (max-width: $breakpoint-mobile) {
      padding-left: 0;
      padding-top: 110px;

      &.sideBySideQuote {
        padding: 110px 0 0 0;
        width: 100%;

        &.quote-left {
          border-right: none;
        }
      }
    }

  }

  &.grayVersion {
    p {
      &:before, &:after {
          content: '"';
      }
    }
    span {
        display: block;
        margin-bottom: 0;
        
    } 
    p, span {
        font-size: 1.125rem;
        text-align: center;
    }
  }

  &.margin {
    margin: $single-space * 4 0 $single-space * 6;
  }
}
