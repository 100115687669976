.post-footer {
    .post-footer-category-container {
        border-bottom: 1px solid $grey-light;
        padding: $single-space * 6 0 $single-space * 3;
        margin-bottom: $single-space * 4;
        @media screen and (min-width: $breakpoint-mobile) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .social-share { 
            white-space: nowrap;
        }
    }
    .post-footer-author-link {
        padding: $single-space * 2 0;
        @media screen and (min-width: $breakpoint-mobile) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .profile-image-name-title {
            margin-top: 0;
        }
        .button {
            margin-top: 1rem;
        }
    }
}