.full-profile {
  display: flex;

  a {
    color: $blue;;

    &:hover {
      text-decoration: underline;
    }
  }

  .full-profile-main {
    flex: 1 0 0%;
    padding-right: $single-space * 8;
  }

  @media screen and (max-width: 56rem) {
    display: block;
    .full-profile-main {
      padding-right: 0;

      .content-banner-header h2 {
        margin-bottom: $single-space * 2;
      }

      section {
        margin-bottom: 50px;

        &:last-child {
          padding-bottom: 0;
        }  
      }
    }
    .callout-table {
      width: 100%;
      margin-bottom: 50px;
    }
  }
}

.profile-insights {
  background: $grey-xlight;
}

.page-profile .content-banner-header {
  width: 328px;
}

.full-profile-header .content-wrapper,
.full-profile-main .content-wrapper {
  padding-left: 0;
  padding-right: 0;
}