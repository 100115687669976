.callout-table {
  max-width: 288px;
  min-width: 200px;
  // width: 24%;
  padding-bottom: $single-space * 5;
  h2 {
    text-transform: uppercase;
    margin-bottom: $single-space;
  }
  p {
    line-height: 22px;
    margin-bottom: $single-space;
  }
  > ul {
    list-style: none;
    margin-top: 5px;
    > li {
        padding: $single-space * 2;
        border-top: 1px solid $grey-light;
        border-left: 1px solid $grey-light;
        border-right: 1px solid $grey-light;
        &:last-child {
            border-bottom: 1px solid $grey-light;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }
  }
}