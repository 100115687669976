.dictionary-list-item {
    border-top: 1px solid $grey-light;
    display: flex;
    padding: $single-space * 4 0;
    @media screen and (max-width: 41rem) {
        flex-direction: column;
    }
    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
    &:last-child {
        margin-bottom: 24px;
    }
    h2{
      flex: auto;
    }
    > div {
        flex: auto;
        max-width: 100%;
        @media screen and (min-width: 41rem) {
          max-width: 60%;
          width: 100%;
        }
    }
    p, h2 {
        margin-bottom: 0;
    }
    p.right-alignment { 
        margin-left: auto;
        @media screen and (max-width: 42rem) {
            margin: 0;
        }
    }
    ul {
        list-style: none;
        flex: 1 0 0%;
        li {
            margin-bottom: 0;
            line-height: 1.1; // to match visual style of paragraph line-height
        }
    }
    > div {
        display: flex;
    }
    @media screen and (max-width: 41rem) {
        h2 {
            margin-bottom: $single-space * 4;
        }
    }
}