.profile-image-name-title {
    display: flex;
    align-items: center;
    margin-top: $single-space * 4;
    .profile-image {
        width: 75px;
        height: 75px;
        display: block;
        border-radius: 50%;
    }
    .profile-image-name-title-content {
        margin-left: $single-space;
        p {
            margin-bottom: 0;
            line-height: 1.375rem;
        }
        h2, h3 {
            line-height: 1.375rem;
            margin-bottom: 0;
        }

        .author-name {
          white-space: nowrap;
        }
    }
}