.profile-card {
    display: flex;
    @media screen and (max-width: 28rem) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
.profile-card-avatar-container {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    margin-right: $single-space * 4;
    overflow: hidden;
    @media screen and (max-width: 28rem) {
        margin: 0 0 $single-space * 4 0;
    }
}
.profile-card-content {
    flex: 1 0 0%;
    @media screen and (max-width: 28rem) {
        text-align: center;
    }
    h2 {
        margin-bottom: 0;
    }
    h3 {
        margin-bottom: $single-space * 2;
    }
}
.profile-summary p {
    @extend .text-body;
}