.hero {
    background: url('/images/home/hero.png') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 625px;
}
.hero-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1 0 0%;
}
.hero-content {
    width: 100%;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1 0 0%;
    @media screen and (max-width: 37rem) {
        align-items: flex-start;
    }
    h2 {
        margin-bottom: $single-space * 3;
        @media screen and (max-width: 37rem) {
            font-size: 2.375rem;
            line-height: 1.2;
            text-align: left;
            margin-top: $single-space;
        }
    }
    .hero-cta-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 48px;
        width: 240px;
        transition: all 500ms cubic-bezier(0, 0, 0.58, 1);
        &.show {
            width: 195px;
        }
        &:hover {
            .hero-cta {
                text-decoration: underline;
            }
            .hero-play-icon {
                transform: scale(1.2);
                transform-origin: center;
                path:first-child {
                    fill: $blue-dark;
                }

            }
        }
    }
    .hero-cta {
        transition: all 500ms cubic-bezier(0, 0, 0.58, 1);
        margin-bottom: 0;
        text-align: right;
        cursor: pointer;
        &.show {
            opacity: 1;
        }
    }
    .hero-play-icon {
        transition: all 250ms cubic-bezier(0, 0, 0.58, 1);
        margin-left: $single-space * 2;
        cursor: pointer;
    }
}

.hero-video {
    object-fit: cover;
    width: 100vw;
    height: 625px;
    position: absolute;
    top: 0;
    left: 0;
  }
.hero-overlay {
    position: absolute;
    width: 100%;
    height: 625px;
    background-color: #00000030;
    top: 0;
    left: 0;
}