.left-content-banner {
    padding: $single-space * 10 0;
    > div {
        width: 40%;
        @media screen and (max-width: 48rem) {
            width: 80%;
        }
        @media screen and (max-width: 30rem) {
            width: 100%;
        }
    }
    p {
        margin-bottom: 0;
    }
}