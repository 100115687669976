.event-card {
    display: flex;
    flex: 1 0 0%;
    border-bottom: 1px solid $grey-light;
    padding-bottom: $single-space * 8;
    margin-bottom: $single-space * 8;
    &:last-child {
        margin-bottom: 0;
    }
    @media screen and (max-width: 39rem ) {
        padding: 0 $single-space * 2 $single-space * 8;
    }
}
.event-card-content {
    @media screen and (min-width: 39rem ) {
        margin-right: $single-space * 8;
    }
    > a:first-of-type {
        margin-right: 5%;
        @media screen and (max-width: 29rem) {
            margin-right: 0;
            margin-bottom: $single-space * 2;
        }
    }
    > a {
        @media screen and (max-width: 29rem) {
            display: block;
            text-align: center;
        }
    }
}
.event-date {
    margin-bottom: $single-space;
    @media screen and (min-width: 39rem ) {
        display: none;
    }
}
.event-card-sidebar {
    display: none;
    @media screen and (min-width: 39rem ) {
        display: block;
    }
    ul {
        list-style: none;
    }
    li {
        p {
            margin: 0;
        }
    }
}
.event-control {
    cursor: pointer;
}
.past-event {
    display: none;
}
.past {
    .event-card {
        display: none;
    }
    .past-event {
        display: flex;
    }
}
.upcoming {
    .event-card {
        display: flex;
    }
    .past-event {
        display: none;
    }
}
.all {
    .event-card {
        display: flex;
    }
}
