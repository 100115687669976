.page-metadata-sidebar {
    margin-right: $single-space * 6;
    @media screen and (max-width: 56rem) {
        margin-bottom: $single-space * 6;
        margin-right:0px;
        .awards-item {
            margin-top: 1rem;
        }
    }
    .award-graphic {
        display: block;
        width: 50%;
        max-width: 10rem;
        margin: 1rem auto;
    }
    &:last-child {
        border-bottom: 6px solid $blue;
        .page-metadata-sidebar-item:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
    &-header {
        margin-bottom: $single-space * 3;
    }
    &-item {
        border-bottom: 1px solid $grey-light;
        padding-bottom: $single-space * 2;
        margin-bottom: $single-space * 2;
        &:last-child {
            margin-bottom: $single-space * 3;
        }
        a {
            display: block;
            margin-bottom: 0;
            line-height: 1.5;
        }
    }
}